import React, { useState, useEffect, useCallback } from 'react';
import './ChatWidget.css'; // Make sure this is imported after other global styles
import io from 'socket.io-client';
import { db } from './Payment/firebase';
import { collection, query, where, getDocs, orderBy, addDoc, doc, updateDoc } from 'firebase/firestore';

const socket = io('https://miniflora-socket-server-495106690462.us-central1.run.app', {
  transports: ['websocket', 'polling'], // Allow WebSocket with fallback to polling
  secure: true,
});


const ChatWidget = React.memo(({ userType, userName, chatType = 'default', recipient, hideHeader = false, onChatEnd }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [typingStatus, setTypingStatus] = useState('');
  const [messageRead, setMessageRead] = useState({});
  const [chatId, setChatId] = useState(null); // Add chatId state to track the chat in Firestore

  // Fetch previous chat messages from Firestore
  const fetchMessagesFromFirestore = useCallback(async () => {
    try {
      if (!chatType || !userName || !recipient) {
        console.error('Error: chatType, userName, or recipient is undefined', { chatType, userName, recipient });
        return;
      }

      const messagesQuery = query(
        collection(db, 'chats'),
        where('chatType', '==', chatType),
        where('sender', 'in', [userName, recipient]),
        where('recipient', 'in', [userName, recipient]),
        where('archivedByClient', '==', false), // Filter for non-archived messages
        orderBy('timestamp', 'asc') // Sort by timestamp
      );
      
      const messageSnapshot = await getDocs(messagesQuery);

      if (!messageSnapshot.empty) {
        const chatData = messageSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setMessages(chatData.map((data) => data)); // Load messages into state
        setChatId(chatData[0]?.id || null); // Save the chat ID if it exists
        localStorage.setItem(`${chatType}-${userName}-${recipient}-Messages`, JSON.stringify(chatData));
      } else {
        
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  }, [chatType, userName, recipient]);

  useEffect(() => {
    fetchMessagesFromFirestore();

    // Join WebSocket room
    socket.emit('join', { userType, sender: userName, recipient, chatType });

    // Listen for incoming messages
    socket.on(`receive-message-${chatType}`, (message) => {
      console.log('Socket connected:', socket.id);
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, message];
        localStorage.setItem(`${chatType}-${userName}-${recipient}-Messages`, JSON.stringify(updatedMessages));
        return updatedMessages;
      });
    });

    // Listen for typing status
    socket.on(`typing-${chatType}`, ({ userType, isTyping }) => {
      setTypingStatus(isTyping ? `${userType} is typing...` : '');
    });

    // Listen for read receipts
    socket.on('message-read', (messageId) => {
      setMessageRead((prevState) => ({ ...prevState, [messageId]: true }));
    });

    return () => {
      socket.off(`receive-message-${chatType}`);
      socket.disconnect();
    };
  }, [chatType, userName, recipient, fetchMessagesFromFirestore]);

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const messageObject = {
        id: Date.now(),
        sender: userName,
        recipient,
        content: newMessage,
        timestamp: new Date().toISOString(),
        read: false,
        chatType: chatType || 'default',
      };

      try {
        if (chatId) {
          // Update existing chat
          const existingChatRef = doc(db, 'chats', chatId);
          await updateDoc(existingChatRef, {
            content: `${messageObject.sender}: ${messageObject.content}`,
            timestamp: new Date().toISOString(),
            read: false,
          });
        } else {
          // Create new chat
          const newChatRef = await addDoc(collection(db, 'chats'), messageObject);
          setChatId(newChatRef.id); // Save the new chat ID
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }

      // Emit message to WebSocket
      socket.emit('sendMessage', messageObject);

      setMessages((prevMessages) => [...prevMessages, { ...messageObject, self: true }]);
      setNewMessage('');
      setIsTyping(false);
    }
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
    setIsTyping(true);
    socket.emit('typing', { userType, chatType, isTyping: true });
  };

  const handleBlur = () => {
    setIsTyping(false);
    socket.emit('typing', { userType, chatType, isTyping: false });
  };

  const handleEndChat = async () => {
    if (onChatEnd) {
      onChatEnd({ id: Date.now(), messages: [...messages] });
    }
    
    // Mark chat as archived for the client instead of deleting
    if (chatId) {
      try {
        const chatRef = doc(db, 'chats', chatId);
        await updateDoc(chatRef, { archivedByClient: true }); // Mark the chat as archived
      } catch (error) {
        console.error('Error archiving chat:', error);
      }
    }

    setMessages([]); // Clear the messages only on the client-side
    setNewMessage(''); // Clear the input field
    setIsTyping(false); // Reset typing status
    setTypingStatus('');
    setIsExpanded(false); // Collapse the chat
    localStorage.removeItem(`${chatType}-${userName}-${recipient}-Messages`); // Clear the stored messages from localStorage
    setChatId(null); // Clear the chat ID
  };

  return (
    <div
      className={`chat-widget-unique ${isExpanded ? 'expanded' : 'collapsed'}`}
      onClick={() => !isExpanded && setIsExpanded(true)} // Expand when clicking in the collapsed state
    >
      {!hideHeader && isExpanded && (
        <div className="chat-header-unique" onClick={(e) => e.stopPropagation() || setIsExpanded(false)}>
          {/* Header content */}
          {chatType === 'client-admin' ? 'Customer Support' : 'Florist Support'}
          <button onClick={handleEndChat} className="end-chat-button-unique">
            End Chat
          </button>
        </div>
      )}
      {isExpanded && (
        <div className="chat-body-unique">
          <div className="messages-unique">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`message-unique ${
                  msg.sender === userName ? 'message-sent-unique' : 'message-received-unique'
                }`}
              >
                <p>{msg.content}</p>
                <span className="message-meta-unique">
                  {msg.sender} - {new Date(msg.timestamp).toLocaleTimeString()}
                  {msg.read && msg.sender === userName ? ' (Read)' : ''}
                </span>
              </div>
            ))}
          </div>
          <div className="typing-status-unique">{typingStatus}</div>
          <input
            className="input-unique"
            type="text"
            value={newMessage}
            onChange={handleInputChange}
            placeholder="Type your message..."
            onFocus={() => setIsTyping(true)}
            onBlur={handleBlur}
          />
          <button
            className="button-unique"
            onClick={handleSendMessage}
            disabled={!newMessage.trim()}
          >
            Send
          </button>
        </div>
      )}
      {!isExpanded && <span>Chat</span>} {/* Collapsed state content */}
    </div>
  );
});

export default ChatWidget;
