import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AccountInformation.css';
import { doc, getDoc, setDoc, query, collection, where, getDocs } from 'firebase/firestore'; // Ensure all necessary Firestore methods are imported
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updatePassword, getAuth } from "firebase/auth";
import { db } from './Payment/firebase';

const AccountInformation = ({ floristProfile, setFloristProfile }) => {
  const [formData, setFormData] = useState(floristProfile || {});
  const [profilePicture, setProfilePicture] = useState(floristProfile?.profilePicture || '');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFloristProfile = async () => {
      if (floristProfile?.uid) {
        try {
          // Query the 'floristProfiles' collection to find the document with the matching UID
          const floristQuery = query(
            collection(db, 'floristProfiles'),
            where('uid', '==', floristProfile.uid) // Match based on the 'uid' field
          );
  
          const querySnapshot = await getDocs(floristQuery);
  
          if (!querySnapshot.empty) {
            // Assuming that UID is unique and only one document will match
            const floristDoc = querySnapshot.docs[0];
            const profileData = floristDoc.data();
            
            // Set the profile data to the component state
            setFormData(profileData);
            setProfilePicture(profileData.profilePicture || '');
  
          } else {
            console.error(`No profile found in Firestore for UID: ${floristProfile.uid}`);
          }
  
        } catch (error) {
          console.error('Error fetching florist profile:', error);
        }
      } else {
        console.error('No UID found in floristProfile.');
      }
    };
  
    fetchFloristProfile();
  }, [floristProfile]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storage = getStorage();
      const storageRef = ref(storage, `profilePictures/${floristProfile.uid}/${file.name}`);
  
      const auth = getAuth();
if (!auth.currentUser) {
  console.error("User is not authenticated.");
  alert("You must be logged in to upload a profile picture.");
  return;
}

if (auth.currentUser.uid !== floristProfile.uid) {
  console.error("UID mismatch between authenticated user and floristProfile.");
  alert("Authentication error. Please try logging in again.");
  return;
}

      try {
        // Upload file to Firebase Storage
        await uploadBytes(storageRef, file);
  
        // Get the downloadable URL
        const downloadURL = await getDownloadURL(storageRef);
  
        // Update the local state and Firestore
        setProfilePicture(downloadURL);
        setFormData((prevData) => ({
          ...prevData,
          profilePicture: downloadURL,
        }));
  
        alert('Profile picture uploaded successfully!');
      } catch (error) {
        console.error('Error uploading profile picture:', error);
        alert('Failed to upload profile picture. Please try again.');
      }
    }
  };
  
  const handleChangePassword = async (e) => {
    e.preventDefault();
    const newPassword = formData.password; // Assume `formData.password` is where the new password is entered
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!newPassword || newPassword.length < 6) {
      alert("Password must be at least 6 characters long.");
      return;
    }
  
    if (user) {
      try {
        await updatePassword(user, newPassword);
        alert("Password updated successfully!");
        setFormData((prevData) => ({ ...prevData, password: "" })); // Clear password input for security
      } catch (error) {
        console.error("Error updating password:", error.message);
        if (error.code === "auth/requires-recent-login") {
          alert("Your session has expired. Please log in again to change your password.");
          navigate("/login");
        } else {
          alert("Failed to update password. Please try again.");
        }
      }
    } else {
      alert("You must be logged in to change your password.");
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const updatedProfile = { ...formData };
  
      // Remove the password field from Firestore updates
      delete updatedProfile.password;
  
      // Ensure profile picture URL is included in the update
      if (profilePicture) {
        updatedProfile.profilePicture = profilePicture;
      }
  
      // Query the floristProfiles collection to find the correct document
      const floristQuery = query(
        collection(db, 'floristProfiles'),
        where('uid', '==', floristProfile.uid)
      );
      const querySnapshot = await getDocs(floristQuery);
  
      if (!querySnapshot.empty) {
        const floristDocId = querySnapshot.docs[0].id; // Get the document ID of the matched profile
  
        // Update the existing document
        const floristRef = doc(db, 'floristProfiles', floristDocId);
        await setDoc(floristRef, updatedProfile, { merge: true });
  
        // Update the state with the new profile data
        setFloristProfile(updatedProfile);
        alert('Changes saved successfully!');
      } else {
        console.error(`No profile found in Firestore for UID: ${floristProfile.uid}`);
        alert('Profile not found. Please try again.');
      }
    } catch (error) {
      console.error('Error saving profile changes:', error);
      alert('Failed to save changes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="account-info-page">
      <h2>Account Information</h2>
      <form className="account-form" onSubmit={handleSubmit}>
        {/* Profile Picture Section */}
        <div className="form-group">
          <label>Profile Picture:</label>
          {profilePicture ? (
            <img src={profilePicture} alt="Profile" className="account-profile-thumbnail" />
          ) : (
            <p>No profile picture uploaded.</p>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="upload-button"
          />
        </div>

        {/* Other Form Fields */}
        <div className="form-group">
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName || ''}
            onChange={handleChange}
            required
          />
          <button
            type="button"
            onClick={handleChangePassword}
            className="btn"
            disabled={!formData.password}
          >
            Update Password
          </button>
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email Address:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password || ''}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="btn">Save Changes</button>
      </form>
    </div>
  );
};

export default AccountInformation;
